
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import ColorPicker, { Item } from '@/modules/common/components/ui-kit/color-picker.vue';
import Percent from '@/modules/common/types/percent.type';
import PercentFilter from '@/modules/common/filters/percent.filter';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CAR_PRICE_TYPE from '../constants/car-price-type.constant';

@Component({
    components: { ColorPicker },
    filters: { PercentFilter },
})
export default class RatesColorPicker extends Vue {
    @inject(CarsServiceS) private carsService!: CarsService;

    get priceType() {
        return this.carsService.storeState.settings.priceType;
    }

    get from(): Percent | null {
        return this.carsService.calendarAssessment ? this.carsService.calendarAssessment[0] : null;
    }

    set from(value) {
        if (value === null || this.to === null) {
            return;
        }
        this.carsService.updateAssessment([value, this.to]);
    }

    get to() {
        return this.carsService.calendarAssessment ? this.carsService.calendarAssessment[1] : null;
    }

    set to(value) {
        if (value === null || this.from === null) {
            return;
        }
        this.carsService.updateAssessment([this.from, value]);
    }

    get fromColor() {
        const { priceType } = this.carsService.storeState.settings;

        if (priceType) {
            if (priceType === CAR_PRICE_TYPE.LOWEST) {
                return 'red';
            }
        }

        return 'green';
    }
    get toColor() {
        const { priceType } = this.carsService.storeState.settings;

        if (priceType) {
            if (priceType === CAR_PRICE_TYPE.LOWEST) {
                return 'green';
            }
        }

        return 'red';
    }

    get fromItems(): Item[] {
        if (!this.carsService.fromAssessmentItems || this.to === null) {
            return [];
        }

        return this.carsService.fromAssessmentItems
            .map((value: number): Item => ({ value, name: `<${Math.round(value * 100)}%`, disabled: value > (this.to || 0) }));
    }
    get toItems(): Item[] {
        if (!this.carsService.toAssessmentItems || this.from === null) {
            return [];
        }

        return this.carsService.toAssessmentItems
            .map((value: number): Item => ({ value, name: `>${Math.round(value * 100)}%`, disabled: value < (this.from || 0) }));
    }
}
