
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import Occupancy from '@/modules/common/components/ui-kit/occupancy.vue';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';

@Component({
    components: {
        CalendarItemWrapper,
        CalendarEventsContainer,
        Occupancy,
    },
})
export default class CarCalendarItemNoAvailability extends Vue {
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;

    @Prop({
        required: true,
        type: Number,
    })
    day!: Day;

    get occupancy(): number | null {
        return Number(this.carsService.occupancy(this.day));
    }

    get isAvailability() {
        return this.carsFilterService.isAvailability;
    }
}
