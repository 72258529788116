
import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import PercentFilter from '@/modules/common/filters/percent.filter';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import Calendar from '@/modules/common/components/ui-kit/calendar.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CalendarItemNoData from '@/modules/common/components/calendar-item-no-data.vue';
import CalendarItemEmpty from '@/modules/common/components/calendar-item-empty.vue';
import CarCalendarItemOutRange from '@/modules/cars/components/car-calendar-item-out-range.vue';
import CarCalendarItem from '@/modules/cars/components/car-calendar-item.vue';
import CarCalendarItemNoAvailability from '@/modules/cars/components/car-calendar-item-no-availability.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import type Day from '@/modules/common/types/day.type';
import DayRateTooltip from './day-rate-tooltip.vue';

let timeout: ReturnType<typeof setTimeout>;

@Component({
    filters: { PercentFilter, MIPriceFilter },
    components: {
        Calendar,
        CalendarItemEmpty,
        CalendarItemNoData,
        CarCalendarItem,
        CarCalendarItemNoAvailability,
        CarCalendarItemOutRange,
        LoaderWrapper,
        DayRateTooltip,
    },
})
export default class CarsCalendar extends Vue {
    @inject(CarsServiceS) carsService!: CarsService;
    @inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    tooltipFocusElement: HTMLElement | null = null;
    tooltipDay: Day | null = null;

    mounted() {
        this.eventsManagerService.saveIsLoadEventByPOS(true);
    }

    get year() {
        return this.documentFiltersService.storeState.settings.year;
    }

    get month() {
        return this.documentFiltersService.storeState.settings.month;
    }

    setTooltipFocus(e: MouseEvent) {
        if (!e.currentTarget) return;

        this.tooltipFocusElement = e.currentTarget as unknown as HTMLElement;
        this.tooltipDay = +(this.tooltipFocusElement.dataset.day || 1) as Day;

        clearTimeout(timeout);
    }

    clearTooltipFocus() {
        timeout = setTimeout(() => {
            this.tooltipDay = null;
        }, 100);
    }

    isNA(day: Day) {
        return this.carsService.allCars(day) === false || this.myCarPrice(day) === 0;
    }

    myCarPrice(day: Day) {
        const currentCar = this.carsService.currentCar(day) as CarsDocumentItemModel;
        return currentCar ? this.carsService.getCarPrice(currentCar) : null;
    }

    beforeDestroy() {
        this.eventsManagerService.saveIsLoadEventByPOS(false);
    }
}
